<script setup>
import Dropdown from '@/Components/Dropdown.vue'
import { computed } from 'vue'

const props = defineProps({
    current: {
        type: String,
        required: true,
    },
})

const lang = computed(() => {
    return props.current == 'fr' ? 'en' : 'fr'
})
</script>

<template>
    <div class="flex items-center relative">
        <Dropdown width="14">
            <template #trigger>
                <span class="uppercase mr-2 text-xs md:text-base">{{ current }}</span>
            </template>

            <template #content>
                <a :href="`/lang/${ lang }`" class="dropdownlink uppercase">
                    {{ lang }}
                </a>
            </template>
        </Dropdown>
    </div>
</template>