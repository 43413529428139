<template>
    <footer class="custom-gradient text-white text-xs lg:text-base">
        <div class="h-[180px] md:h-[90px] custom-side-padding grid grid-rows-3 md:flex justify-items-center md:justify-between items-center">
            <p class="row-start-3 text-xs lg:text-base">©2024 INFRAROUGE</p>
            <div class="flex row-start-1">
                <a href="/legals">{{ $t('showcase.legal') }}</a>
                <p class="mx-4">|</p>
                <a href="/privacy">{{ $t('showcase.privacy_policy') }}</a>
            </div>
            <div class="row-start-2 flex items-center">
                <a href="https://connectedby.lefrenchdesign.org/_/company?id=65ce76c04931755ed1c53702" target="_blank">
                    <img src="/img/logo_french_design.png" alt="French Design">
                </a>
                <a href="https://www.linkedin.com/company/infrarouge-design/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" class="mercado-match" width="40" height="40" focusable="false" alt="Linkedin" >
                        <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                    </svg>
                </a>
            </div>
        </div>
    </footer>
</template>